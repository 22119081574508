<template>
  <div class="profile-title">
    Profile
  </div>
  <ProfilePasswordForm />
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  setup() {
    const store = useStore()
    const username = computed(() => store.getters.getFullName)

    return { username }
  },
}
</script>

<style>
.profile-title {
  font-size: 22pt;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
